<template>
  <div class="admin-guidance-create-external-link">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">Create External Link</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >
      </div>
    </div>
    <!-- / Page Header -->

    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Form -->
    <div v-if="!isLoading" class="form">
      <a-form-item label="Link Name">
        <a-input size="large" v-model="externalLinkLocal.displayName"></a-input>
      </a-form-item>

       <a-form-item label="URL">
        <a-input size="large" v-model="externalLinkLocal.url"></a-input>
      </a-form-item>

      <a-form-item label="Visibility">
        <a-radio-group v-model="externalLinkLocal.scope">
          <a-radio :value="1">Shown to organisation</a-radio>
          <!-- <a-radio :value="2">Shown to whole tenant</a-radio> -->
          <a-radio :value="3">Hidden from lists</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="Display Order">
        <a-radio-group v-model="externalLinkLocal.rank">
          <a-radio v-for="(rank, i) in ranks" :key="i" :value="rank.rank">{{
            rank.label
          }}</a-radio>
        </a-radio-group>
      </a-form-item>
    </div>
    <!-- / Form -->

    <a-button
      @click.prevent="save"
      class="btn-rounded save-button"
      size="large"
      type="primary"
      >Save</a-button
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
const _ = require("lodash");
import guidanceHelpers from "../../../../helpers/guidance";
import externalLinks from "../../../../api/guidance/external-links";

export default {
  components: { LoadingScreen },

  data() {
    return {
      externalLinkLocal: {
        displayName: '',
        url: '',
        scope: 1,
        rank: 0,
      },

      isSaving: false,
    };
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
  },

  methods: {
    goBack() {
      this.$router.push("/admin/guidance/external-links");
    },

    getValidationErrors() {
      let errors = [];
      if (this.externalLinkLocal.displayName.trim().length == 0) {
        errors.push("Please provide a link name");
      }
      if (this.externalLinkLocal.url.trim().length == 0) {
        errors.push("Please provide a URL");
      }
      return errors;
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      let params = {
        ...this.externalLinkLocal,
        ownerId: this.selectedOrganisation.id,
      };

      externalLinks.addExternalLink(vm.tenantId, params).then(() => {
        vm.$message.success('External link created successfully');
        vm.$router.push('/admin/guidance/external-links');
      }).catch(e => {
        console.log(e);
      });
    },
  },

  computed: {
    ...mapGetters("missionControl", {
      presences: "presences",
    }),

    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
    }),

    myPresenceForOrg() {
      return _.find(this.presences, {
        ownerId: this.selectedOrganisation.id,
      });
    },

    ranks() {
      return guidanceHelpers.listRankOptions();
    },
  },
};
</script>

<style scoped lang="scss">
.admin-guidance-create-external-link {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .top-row {
    display: flex;
    .left {
      flex-shrink: 1;
    }
    .right {
      padding-left: 20px;
      flex-grow: 1;
    }
  }

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 20px;
  }
}
</style>